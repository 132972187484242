import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"
import confetti from "canvas-confetti"

export default class extends Controller {
  static targets = ["checkbox", "button"]

  static values = {
    completed: Boolean,
  }

  connect() {
    this.toggleClasses()
  }

  toggle() {
    this.toggleDatabase()
    this.toggleClasses()
  }

  async toggleDatabase() {
    const request = new FetchRequest("patch", this.data.get("url"))
    const response = await request.perform()
    if (response.ok) {
      this.completedValue = !this.completedValue
      this.toggleClasses()
      if (this.completedValue == true) {
        this.confetti()
      }
    }
  }

  toggleClasses() {
    if (this.completedValue === true) {
      this.buttonTarget.classList.remove("text-gray-500", "border", "border-gray-400")
      this.buttonTarget.classList.add("bg-green-200", "text-green-800")
      this.buttonTarget.innerHTML = "Completed"
    } else {
      this.buttonTarget.classList.add("text-gray-500", "border", "border-gray-400")
      this.buttonTarget.classList.remove("bg-green-200", "text-green-800")
      this.buttonTarget.innerHTML = "Mark Complete"
    }
  }

  confetti() {
    var myCanvas = document.createElement("canvas")
    myCanvas.classList.add("absolute", "inset-0", "z-50", "w-screen")
    document.body.appendChild(myCanvas)

    var myConfetti = confetti.create(myCanvas, {
      resize: true,
      useWorker: true,
    })

    myConfetti({
      particleCount: 100,
      spread: 150,
      origin: { y: 0.6 },
    })

    setTimeout(() => {
      myCanvas.classList.remove("absolute", "inset-0", "z-50", "w-screen")
      document.body.removeChild(myCanvas)
      myConfetti.reset()
    }, 2000)
  }
}
