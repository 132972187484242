import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js";
import Sortable from "sortablejs"

export default class extends Controller {
  static targets = ["category"]
  connect() {
    // Instead of onEnd this can be moved over to onChange but will have to research more
    // onChange: ((event) => { console.log("change event : ", event) })
    this.sortable = Sortable.create(this.element.children[0], {
      group: "nested",
      animation: 150,
      handle: ".drag-handle",
      draggable: ".category",
      onEnd: this.end.bind(this),
    })
  }

  async end(event) {
    let categories = [...event.to.children]
    let category_ids = categories.map(category => parseInt(category.dataset.categoryId))

    const request = new FetchRequest("post", event.to.dataset.moveUrl, { body: JSON.stringify({ category: { category_ids: category_ids } }) })
    await request.perform()
  }
}
