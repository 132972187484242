import bucketMerging from './matchingStrategies/bucketMerging';
import cruMerging from './matchingStrategies/cruMerging';
import swapMerging from './matchingStrategies/swapMerging';
import cruBustUp from './matchingStrategies/cruBustUp'

const crumatching = {
  getMatchingStrategy(name) {
    switch (name) {
      case "bucket":
        return bucketMerging;
      case "cru":
        return cruMerging;
      case "swap":
        return swapMerging;
      case "cruBustUp":
        return cruBustUp;
      default:
        return bucketMerging;
    }

  }
}

export default crumatching;