import { Controller } from "@hotwired/stimulus"
import tippy from "tippy.js"

export default class extends Controller {

  static targets = [
    "dateInputGroup",
    "dateInput",
    "alertIcon",
  ]

  static values = {
    "intention": Object,
    "intentionAction": Object,
    "intentionDate": String
  }

  connect() {
    let intentionDate = Date.parse(this.intentionValue["deadline"])
    let actionDate = Date.parse(this.intentionActionValue["deadline"])
    let svg = document.querySelector("#alert-icon")

    if (actionDate > intentionDate) {
      this.dateInputGroupTarget.classList.add("bg-yellow-100", "rounded")
      svg.classList.remove("hidden")
    }

    tippy(document.querySelectorAll("[data-tippy-content]"), {
      theme: "dark",
    })
  }

  selectedDate(event) {
    let intentionDate = Date.parse(this.intentionDateValue)
    let actionDate = Date.parse(event.target.value)
    let svg = event.target.parentElement.querySelector("svg")

    if (actionDate > intentionDate) {
      this.dateInputGroupTarget.classList.add("bg-yellow-100", "rounded")

      svg.classList.remove("hidden")
      svg.classList.add("inline-block")

      let tippyInstance = tippy(svg)
      tippyInstance.show()
      setTimeout(() => {
        tippyInstance.destroy()
      }, 1500)

    } else {
      this.dateInputGroupTarget.classList.remove("bg-yellow-100", "rounded")
      svg.classList.add("hidden")
      svg.classList.remove("inline-block")
    }
  }

}