import { Controller } from "@hotwired/stimulus"
import tippy from "tippy.js"

export default class extends Controller {
  static targets = [
    "newForm",
    "newActionButton",
    "dateInputGroup",
    "dateInput",
  ]
  static values = {
    "intention": String,
  }

  newAction() {
    this.newFormTarget.classList.remove("hidden")
    this.newActionButtonTarget.classList.add("hidden")
  }

  selectedDate(event) {
    event.preventDefault()

    let intentionDate = Date.parse(this.intentionValue)
    let actionDate = Date.parse(event.target.value)
    let svg = event.target.parentElement.querySelector("svg")

    if (actionDate > intentionDate) {
      this.dateInputGroupTarget.classList.add("bg-yellow-100", "rounded")

      svg.classList.remove("hidden")
      svg.classList.add("inline-block")

      let tippyInstance = tippy(svg)
      tippyInstance.show()
      setTimeout(() => {
        tippyInstance.destroy()
      }, 1500)

    } else {
      this.dateInputGroupTarget.classList.remove("bg-yellow-100", "rounded")
      svg.classList.add("hidden")
      svg.classList.remove("inline-block")
    }
  }
}
