import { Controller } from "@hotwired/stimulus"
import { createPopper } from '@popperjs/core';

export default class extends Controller {
  static targets = ["content", "trigger"];

  connect() {
  }

  disconnect() {
    if (this.hasContentTarget) {
      this.contentTarget.remove();
    }
  }

  mouseOver(event) {
    event.preventDefault()
    const element = event.target

    this.contentTargets.forEach((el, i) => {
      if (element.dataset.hovercardIndex == i) {
        el.classList.remove("hidden")

        this.popperInstance = createPopper(element, el, {
          placement: 'top',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 7],
              },
            },
          ],
        });

        // Enable the event listeners
        this.popperInstance.setOptions({
          modifiers: [{ name: 'eventListeners', enabled: true }],
        });
      }
    })
  }

  mouseOut(event) {
    event.preventDefault()
    const element = event.target

    this.contentTargets.forEach((el, i) => {
      if (element.dataset.hovercardIndex == i) {
        el.classList.add('hidden')

        // Disable the event listeners
        this.popperInstance.setOptions({
          modifiers: [{ name: 'eventListeners', enabled: false }],
        });

        this.popperInstance.destroy()
      }
    })
  }
}