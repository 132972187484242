import { Controller } from "@hotwired/stimulus";
import { createPopper } from "@popperjs/core";
import { enter, leave } from "el-transition"

export default class extends Controller {
  static targets = ["trigger", "content"]
  static values = {
    options: String
  }

  connect() {
    this.popperInstance = createPopper(this.triggerTarget, this.contentTarget, JSON.parse(this.optionsValue))
  }

  disconnect() {
    this.popperInstance.destroy()
  }

  show() {
    enter(this.contentTarget)
    this.popperInstance.update()
  }

  hide() {
    leave(this.contentTarget)
    this.popperInstance.update()
  }
}
