import { Controller } from "@hotwired/stimulus"
import Vue from 'vue'
import Matching from '../matching/matching.vue'

export default class extends Controller {
  static targets = ["load"]

  connect() {
    // You can wrap this in a setTimout() to also see it loading after stimulus is connected
    this.initializeComponent()
  }

  initializeComponent() {
    // props cannot be sent to vue-component as normal.  instead you would have to get them from stimulus and then pass it on to
    // your vue instance.
    let props = JSON.parse(this.data.get('props'))

    this.vue = new Vue({
      el: this.loadTarget,
      render: h => h(Matching, {
        props: { matchableUsers: props.matchableUsers, generation: props.generation, starting: props.starting },
      })
    });
  }
}