import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["videoEmbed", "eventDate", "eventType"]

  connect() {
    if (
      this.eventTypeTarget.value == "Video" ||
      this.eventTypeTarget.value == "Gathering Guide"
    ) {
      this.videoEmbedTarget.hidden = false
      this.eventDateTarget.hidden = true
    }
  }

  selectedType(event) {
    event.preventDefault()
    let value = event.currentTarget.value

    if (value === "Live") {
      this.videoEmbedTarget.hidden = true
      this.eventDateTarget.hidden = false
    } else {
      this.videoEmbedTarget.hidden = false
      this.eventDateTarget.hidden = true
    }
  }
}
