import { post, patch } from "@rails/request.js"
import CheckboxSelectAll from "stimulus-checkbox-select-all"

export default class extends CheckboxSelectAll {
  static targets = ["setMarked", "igDate", "error", "errorMessage"]
  connect() {
    super.connect()
  }

  async update(event) {
    event.preventDefault()
    let checked = this.checked.map((checkbox) => checkbox.value)

    const response = await post(event.target.dataset.bulkUrl, {
      body: JSON.stringify({ ids: checked }),
    })
    if (response.ok) {
      Turbo.clearCache()
      Turbo.visit(window.location)
    } else {
      // Raise error
      console.log("Error")
    }
  }

  async updateUserProfiles(event) {
    event.preventDefault()
    let checked = this.checked.map((checkbox) => checkbox.value)

    const response = await patch(event.target.dataset.url, {
      body: JSON.stringify({
        ids: checked,
        preferred_ig_dates: this.igDateTarget.value,
      }),
    })
    if (response.ok) {
      Turbo.clearCache()
      Turbo.visit(window.location)
    } else {
      const body = await response.json
      this.errorMessageTarget.innerText = body.message
      this.errorTarget.hidden = false
    }
  }

  // Trigger on toggle
  toggle(e) {
    super.toggle(e)
    this.countChecked()
  }

  refresh(e) {
    super.refresh(e)
    this.countChecked()
  }

  countChecked() {
    if (this.checked.length > 0) {
      this.setMarkedTargets.forEach((target) => {
        target.classList.remove("hidden")
      })
    } else {
      this.setMarkedTargets.forEach((target) => {
        target.classList.add("hidden")
      })
    }
  }
}
