import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['button', 'textInput', 'output', 'form']
  static values = { maxInput: Number }
  connect() {
    let length = this.textInputTarget.value.length
    this.characterCount = this.maxInputValue
    this.checkLength(length)
    this.outputTarget.textContent = `${this.maxInputValue - length} characters remaining`
  }

  change() {
    let length = this.textInputTarget.value.length
    this.outputTarget.textContent = `${this.maxInputValue - length} characters remaining`
    this.checkLength(length)
  }

  checkLength(length) {
    if (length > this.characterCount) {
      console.log("over char count max")
      this.outputTarget.classList.add('text-red-500')
      this.textInputTarget.classList.add('border', 'border-red-500')
    } else {
      this.outputTarget.classList.remove('text-red-500')
      this.textInputTarget.classList.remove('border', 'border-red-500')
    }
  }
}