import { Controller } from "@hotwired/stimulus"
import Cookies from 'js-cookie'

export default class extends Controller {
  static targets = ["pulse"]

  connect() {
    let firstTimer = Cookies.get('hamburger')
    if (firstTimer === 'true') {
      this.pulseTarget.remove()
    }
  }

  update() {
    Cookies.set('hamburger', true, { expires: 999 })
    this.pulseTarget.remove()
  }
}
