import { Controller } from "@hotwired/stimulus";
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = [
    "weekly",
    "monthly",
    "schedulePane",
    "dayPanel",
    "monthPanel",
    "dayOfMonth",
    "dayOfWeek",
    "dayOfWeekDay",
    "hostSelect",
    "type",
    "endDate",
    "occurrences",
    "occurrencesInput",
    "endDateInput",
    "dayOfMonthInput",
    "dayOfWeekInput",
    "monthlyOptionSelect",
  ]

  static values = {
    persisted: String,
    cruId: Number,
    gathering: Object,
  }

  connect() {
    // Based on what type of schedule monthly/weekly it will show the correct fields
    if (this.typeTarget.value) {
      this.schedulePaneTarget.classList.remove("hidden")
    }

    if (this.gatheringValue) {
      let ends = document.getElementById("ends")

      if (this.gatheringValue.end_date) {
        this.endDateTarget.classList.remove("hidden")
        this.occurrencesTarget.classList.add("hidden")
        ends.options[1].selected = true;
      }

      if (this.gatheringValue.occurrences) {
        this.occurrencesTarget.classList.remove("hidden")
        this.endDateTarget.classList.add("hidden")
        ends.options[0].selected = true;
      }

      if (this.gatheringValue.rule_validations && this.gatheringValue.rule_validations.day_of_month.length > 0) {
        this.monthlyOptionSelectTarget.value = "day"
        this.dayOfMonthTarget.classList.remove("hidden")
        this.dayOfWeekTarget.classList.add("hidden")
      } else if (this.gatheringValue.rule_validations && this.gatheringValue.rule_validations.day_of_week) {
        this.monthlyOptionSelectTarget.value = "week"
        this.dayOfWeekTarget.classList.remove("hidden")
        this.dayOfMonthTarget.classList.add("hidden")
      }
    }

    if (this.typeTarget.value === "Monthly") {
      this.monthly()
    }
    if (this.typeTarget.value === "Weekly") {
      this.weekly()
    }

    if (this.persistedValue === "true") {
      let target = this.hostSelectTarget.id
      let cruId = this.gatheringValue.cru_id
      get(`/backoffice/gathering_schedules/cru_users?target=${target}&cru_id=${cruId}`, {
        responseKind: "turbo-stream"
      })
    }
  }

  selectedCru(event) {
    let cruId = event.currentTarget.value
    let target = this.hostSelectTarget.id

    get(`/backoffice/gathering_schedules/cru_users?target=${target}&cru_id=${cruId}`, {
      responseKind: "turbo-stream"
    })
  }

  selectedRecurrence(event) {
    event.preventDefault()
    let value = event.target.value

    if (value === 'Monthly') {
      this.monthly()
    } else if (value === "Weekly") {
      this.weekly()
    }

    this.schedulePaneTarget.classList.remove("hidden")
  }

  monthly() {
    this.weeklyTargets.forEach((e) => {
      e.classList.add('hidden')
    })
    this.monthlyTargets.forEach((e) => {
      e.classList.remove('hidden')
    })

    // clear out the rule_validation[day] checkboxes
    let boxes = document.querySelectorAll('input[type="checkbox"]:checked')
    boxes.forEach(target => target.checked = false)
  }

  weekly() {
    this.monthlyTargets.forEach((e) => {
      e.classList.add('hidden')
    })
    this.weeklyTargets.forEach((e) => {
      e.classList.remove('hidden')
    })

    // Clear out the rule_validations[day_of_month} and rule_validations[day_of_week]
    this.dayOfMonthInputTarget.value = ""
    this.dayOfWeekInputTargets.forEach(target => target.value = '')
  }

  toggleOccursOn(event) {
    event.preventDefault();
    let selectedTarget = event.target.value
    if (selectedTarget === "week") {
      this.dayOfMonthTarget.classList.add("hidden")
      this.dayOfWeekTarget.classList.remove("hidden")
      this.dayOfMonthInputTarget.value = ''
    }
    if (selectedTarget === "day") {
      this.dayOfWeekTarget.classList.add("hidden")
      this.dayOfMonthTarget.classList.remove("hidden")
      this.dayOfWeekInputTargets.forEach(target => target.value = '')
    }
  }

  endByToggle(event) {
    event.preventDefault();
    let selectedTarget = event.target.value
    if (selectedTarget === "occurrences") {
      this.occurrencesTarget.classList.remove("hidden")
      this.endDateTarget.classList.add("hidden")
      this.endDateInputTarget.value = ''
    }
    if (selectedTarget === "until") {
      this.endDateTarget.classList.remove("hidden")
      this.occurrencesTarget.classList.add("hidden")
      this.occurrencesInputTarget.value = ''
    }
  }
}