import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["removeList"]

  connect() {
    this.removeListArray = []
  }

  removeSelected(e) {
    e.preventDefault()
    let attachmentId = e.params.value

    if (e.target.checked) {
      this.removeListArray.push(attachmentId)
      this.removeListTarget.value = this.removeListArray
    } else {
      let index = this.removeListArray.indexOf(attachmentId)
      this.removeListArray.splice(index, 1)
      this.removeListTarget.value = this.removeListArray
    }
  }
}
