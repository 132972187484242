import matchingUtilities from '../matchingUtilities'

const cruMerging = {

  suggest(suggestedUsers, existingCrus, generation, startingNumber) {
    let firstPass = suggest(suggestedUsers, existingCrus, matchingUtilities.MEMBERS_PER_CRU, false);
    let oneSmaller = suggest(firstPass.unmatchable, [], matchingUtilities.MEMBERS_PER_CRU - 1, false);

    let finalCrus = firstPass.crus.concat(oneSmaller.crus);
    let finalUnmatchable = oneSmaller.unmatchable;

    // Now that we have a bunch of crus of a size (and one less than that size)
    // try to add anyone left over to the closest cru with an IG date that works
    for (let lastTryIndex = finalUnmatchable.length - 1; lastTryIndex >= 0; lastTryIndex--) {
      let user = finalUnmatchable[lastTryIndex];

      finalCrus.sort((first, second) => {
        let firstDist = matchingUtilities.averageToUserDistance(first.users, user);
        let secondDist = matchingUtilities.averageToUserDistance(second.users, user);
        return firstDist - secondDist;
      })

      for (let cruIndex = 0; cruIndex < finalCrus.length; cruIndex++) {
        let cru = finalCrus[cruIndex];
        let distance = matchingUtilities.averageToUserDistance(cru.users, user);
        if (isFinite(distance) && (cru.users.length < matchingUtilities.MEMBERS_PER_CRU + 1)) {
          cru.users.push(user);
          finalUnmatchable.splice(lastTryIndex, 1);
          break;
        }
      }
    }

    // Rename them and assign IG
    for (let cruIndex = 0; cruIndex < finalCrus.length; cruIndex++) {
      let cruToFixUp = finalCrus[cruIndex]
      cruToFixUp.generation = generation
      cruToFixUp.number = (startingNumber + cruIndex)
      cruToFixUp.ig = cruToFixUp.ig[Math.floor(Math.random() * cruToFixUp.ig.length)];
    }

    return {
      crus: finalCrus,
      unmatchable: finalUnmatchable
    }
  },
}

export default cruMerging;

function suggest(suggestedUsers, existingCrus, membersPerCru, keepWrongSizedCru) {
  let suggestedCrus = [].concat(existingCrus);

  // Create a new cru for each user
  for (let i = suggestedUsers.length - 1; i >= 0; i--) {
    suggestedCrus.push({
      generation: undefined, // Selected later
      number: undefined,
      users: [suggestedUsers[i]],
      ig: [...suggestedUsers[i].gatherings],
      timezone: undefined
    });
    suggestedUsers.splice(i, 1);
  }

  // Iterate on Merging Crus until we converge (for now fixed iterations)
  for (let iterations = 0; iterations < suggestedCrus.length * membersPerCru; iterations++) {

    // If we are down to 1 cru there is nothing else we can iterate on
    if (suggestedCrus.length <= 1) {
      break;
    }

    // Get the shortest Cru, this is the one to get rid of
    suggestedCrus.sort((first, second) => {
      return first.users.length - second.users.length;
    });

    let nextCruToBust = suggestedCrus[0];

    // If the selected cru has length members per cru then we have converged best we can
    if (nextCruToBust.users.length >= membersPerCru) {
      break;
    }
    suggestedCrus.splice(0, 1);

    // Spread the users from this cru out into other crus based on distance and IG
    for (let userToMergeIndex = nextCruToBust.users.length - 1; userToMergeIndex >= 0; userToMergeIndex--) {

      let user = nextCruToBust.users[userToMergeIndex];

      suggestedCrus.sort((first, second) => {
        let firstDist = matchingUtilities.averageToUserDistance(first.users, user);
        let secondDist = matchingUtilities.averageToUserDistance(second.users, user);
        return firstDist - secondDist;
      });

      // Insert into the closest not full cru
      for (let insertIndex = 0; insertIndex < suggestedCrus.length; insertIndex++) {
        if (suggestedCrus[insertIndex].users.length < membersPerCru) {
          let distance = matchingUtilities.averageToUserDistance(suggestedCrus[insertIndex].users, user);

          if (isFinite(distance)) {
            suggestedCrus[insertIndex].users.push(user);
            nextCruToBust.users.splice(userToMergeIndex, 1);
            break;
          }
        }
      }
    }

    // If we cant merge all the users, return the unmergable users
    if (nextCruToBust.users.length !== 0) {
      suggestedUsers = suggestedUsers.concat(nextCruToBust.users);
      nextCruToBust.users = [];
    }
  }

  // Remove any crus that dont meet the cru size limit
  for (let cruIndex = suggestedCrus.length - 1; cruIndex >= 0; cruIndex--) {
    if (!keepWrongSizedCru && suggestedCrus[cruIndex].users.length < membersPerCru) {
      suggestedUsers = suggestedUsers.concat(suggestedCrus[cruIndex].users)
      suggestedCrus[cruIndex].users = [];
    }
    if (suggestedCrus[cruIndex].users.length === 0) {
      suggestedCrus.splice(cruIndex, 1);
    }
  }

  return {
    crus: suggestedCrus,
    unmatchable: suggestedUsers
  }
}