const matchingUtilities = {

  // We could have backend provide this too
  MEMBERS_PER_CRU: 8,

  cruDistance(cru1, cru2) {
    // Infinity if no igs match;
    for (let igIndex = 0; igIndex < cru1.ig.length; igIndex++) {
      if (cru2.ig.includes(cru1.ig[igIndex])) {
        let distance = this.averageUsersGroupDistance(cru1.users, cru2.users);
        return distance;
      }
    }
    return Number.POSITIVE_INFINITY;
  },

  averageUsersGroupDistance(group1, group2) {
    // Infinity if no users
    if (group1.length === 0 || group2.length === 0) {
      return Number.POSITIVE_INFINITY;
    }
    let distances = [];
    group1.forEach((toAve) => {
      distances.push(this.averageToUserDistance(group2, toAve));
    });
    // If there is nothing in the user set, we are as close as possible
    return distances.reduce((a, b) => a + b) / distances.length;
  },

  averageToUserDistance(users, user) {
    // If there are no users, we are very far away
    if (users.length === 0) {
      return Number.POSITIVE_INFINITY;
    }
    let distances = [];
    users.forEach((toAve) => {
      distances.push(this.userDistance(toAve, user));
    });
    // If there is nothing in the user set, we are as close as possible
    return distances.reduce((a, b) => a + b) / distances.length;
  },

  userDistance(first, second) {
    const firstVector = this.userVector(first);
    const secondVector = this.userVector(second);

    for (let gatheringIndex = 0; gatheringIndex < second.gatherings.length; gatheringIndex++) {
      if (first.gatherings.includes(second.gatherings[gatheringIndex])) {
        let partial = 0;

        for (let i = 0; i < firstVector.length; i++) {
          partial = partial + Math.pow(firstVector[i] + secondVector[i], 2);
        }

        return Math.sqrt(partial);
      }
    }
    return Number.POSITIVE_INFINITY;
  },

  userVector(user) {
    // NOTE: Vectors MUST be the same length 
    // values should be normalized from 0 to 1 then multiplied by importance
    let seniority = 0;

    // TODO: this is HUGE coupling, would be nice to have the backend provide
    //       values for these
    switch (user.seniority) {
      case 'Executive':
        seniority = 4;
        break;
      case 'Senior':
        seniority = 3;
        break;
      case 'Mid':
        seniority = 2;
        break;
      case 'Junior':
        seniority = 1
        break;
      default:
        seniority = 0;
    }

    let age = user.age;


    let vector = [2 * (age / 100.0), seniority / 4.0];
    return vector;
  }
}

export default matchingUtilities;