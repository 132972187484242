// Custom TailwindCSS modals for confirm dialogs

const Rails = require("@rails/ujs")

// Cache a copy of the old Rails.confirm since we'll override it when the modal opens
const old_confirm = Rails.confirm

// Elements we want to listen to for data-confirm
const elements = ["a[data-confirm]", "button[data-confirm]", "input[type=submit][data-confirm]"]

const createConfirmModal = (element) => {
  var id = "confirm-modal-" + String(Math.random()).slice(2, -1)
  var confirm = element.dataset.confirm

  var content = `
    <div id="${id}" class="animated fadeIn fixed top-0 left-0 w-full h-full flex items-center justify-center z-50" style="background-color: rgba(0, 0, 0, 0.6);">
      <div class="">

        <div class="bg-white mx-auto rounded shadow p-8 max-w-lg">
          <div class="flex flex-col items-center justify-center text-center">
            <h4 class="font-bold text-lg">${confirm}</h4>
            <p class="text-gray-500 text-base mt-2">You will not be able to undo this.</p>
          </div>

          <div class="flex justify-center items-center flex-wrap mt-10">
            <button data-behavior="cancel" class="text-sm border border-gray-400 mr-4 rounded-full px-8 py-2 hover:bg-gray-50">Cancel</button>
            <button data-behavior="commit" class="text-sm border border-transparent bg-gray-900 text-gray-100 rounded-full px-8 py-2 hover:bg-gray-700 focus:outline-none">Confirm</button>
          </div>
        </div>
      </div>
    </div>
  `

  element.insertAdjacentHTML("afterend", content)

  var modal = element.nextElementSibling
  element.dataset.confirmModal = `#${id}`

  modal.addEventListener("keyup", (event) => {
    if (event.key === "Escape") {
      event.preventDefault()
      element.removeAttribute("data-confirm-modal")
      modal.remove()
    }
  })

  modal.querySelector("[data-behavior='cancel']").addEventListener("click", (event) => {
    event.preventDefault()
    element.removeAttribute("data-confirm-modal")
    modal.remove()
  })
  modal.querySelector("[data-behavior='commit']").addEventListener("click", (event) => {
    event.preventDefault()

    // Allow the confirm to go through
    Rails.confirm = () => {
      return true
    }

    // Click the link again
    element.click()

    // Remove the confirm attribute and modal
    element.removeAttribute("data-confirm-modal")
    Rails.confirm = old_confirm

    modal.remove()
  })

  modal.querySelector("[data-behavior='commit']").focus()
  return modal
}

// Checks if confirm modal is open
const confirmModalOpen = (element) => {
  return !!element.dataset.confirmModal
}

const handleConfirm = (event) => {
  // If there is a modal open, let the second confirm click through
  if (confirmModalOpen(event.target)) {
    return true

    // First click, we need to spawn the modal
  } else {
    createConfirmModal(event.target)
    return false
  }
}

// When a Rails confirm event fires, we'll handle it
Rails.delegate(document, elements.join(", "), "confirm", handleConfirm)
