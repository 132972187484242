import { Controller } from "@hotwired/stimulus"

let overlapObserver = new IntersectionObserver(function (entries) {
  entries.forEach((entry) => {
    if (entry['isIntersecting'] === true) {
      if (entry['intersectionRatio'] === 1) {
        console.log('Target is fully visible in the screen');
        entry['target'].classList.remove("blur-xxs");
      } else {
        entry['target'].classList.add("blur-xxs");
      }
    }
  })
}, { root: document.getElementById("nav-container"), threshold: [0, 0.5, 1] });

export default class extends Controller {
  static targets = ["item"]

  connect() {
    // Set timeout here guarentees that all controllers and targets
    // connect before this runs
    setTimeout(() => {
      this.scrollIntoView()
      this.itemTargets.forEach((element) => {
        overlapObserver.observe(element)
      })
    })
  }

  toggleNav() {
    // TODO: make these targets instead
    let closed = document.getElementById("bottom-sheet-closed")
    let open = document.getElementById("bottom-sheet")

    if (closed.classList.contains("hidden")) {
      // close nav
      open.classList.add("hidden")
      closed.classList.remove("hidden")
    } else {
      // open nav
      closed.classList.add("hidden")
      open.classList.remove("hidden")
    }
  }

  scrollIntoView() {
    this.itemTargets.forEach((element) => {
      if (element.classList.contains("active")) {
        element.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' })
      }
    });
  }
}