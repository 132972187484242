import matchingUtilities from '../matchingUtilities'

const cruMerging = {

  suggest(suggestedUsers, existingCrus, generation, startingNumber) {

    let suggestedCrus = [].concat(existingCrus);

    // Create a new cru for each user
    for (let i = suggestedUsers.length - 1; i >= 0; i--) {
      suggestedCrus.push({
        generation: undefined,
        number: undefined,
        users: [suggestedUsers[i]],
        ig: [...suggestedUsers[i].gatherings],
        timezone: undefined,
      });
      suggestedUsers.splice(i, 1);
    }

    // Iterate on Merging Crus until we converge (for now fixed iterations)
    for (let iterations = 0; iterations < 100; iterations++) {

      // Get the shortest Cru, this is the one to get rid of
      suggestedCrus.sort((first, second) => {
        return first.users.length - second.users.length;
      });

      let cruToMergeAway = suggestedCrus.splice(0, 1)[0];

      suggestedCrus.sort((first, second) => {
        let firstDist = matchingUtilities.cruDistance(first, cruToMergeAway);
        let secondDist = matchingUtilities.cruDistance(second, cruToMergeAway);
        return firstDist - secondDist;
      })

      for (let insertIndex = 0; insertIndex < suggestedCrus.length; insertIndex++) {
        let insertableCru = suggestedCrus[insertIndex];
        let distance = matchingUtilities.cruDistance(insertableCru, cruToMergeAway);
        if (Number.isFinite(distance) && (insertableCru.users.length + cruToMergeAway.users.length) <= matchingUtilities.MEMBERS_PER_CRU) {
          insertableCru.users = insertableCru.users.concat(cruToMergeAway.users);

          // Fix up gathering dates
          let candidateDates = [...insertableCru.users[0].gatherings];
          insertableCru.users.forEach((user) => {
            for (let candidateIndex = candidateDates.length - 1; candidateIndex >= 0; candidateIndex--) {
              if (!user.gatherings.includes(candidateDates[candidateIndex])) {
                candidateDates.splice(candidateIndex, 1);
              }
            }
          })
          insertableCru.gatherings = candidateDates;

          cruToMergeAway = null;
          break;
        }
      }

      // If we cant merge the cru, add it back as a cru
      if (cruToMergeAway != null) {
        suggestedCrus.push(cruToMergeAway);
      }
    }

    // Remove any empty crus
    for (let cruIndex = suggestedCrus.length - 1; cruIndex >= 0; cruIndex--) {
      if (suggestedCrus[cruIndex].users.length === 0) {
        suggestedCrus.splice(cruIndex, 1);
      }
    }

    // Rename them and assign IG
    for (let cruIndex = 0; cruIndex < suggestedCrus.length; cruIndex++) {
      let cruToFixUp = suggestedCrus[cruIndex]
      cruToFixUp.generation = generation
      cruToFixUp.number = (startingNumber + cruIndex)
      cruToFixUp.ig = cruToFixUp.ig[Math.floor(Math.random() * cruToFixUp.ig.length)];
    }

    return {
      crus: suggestedCrus,
      unmatchable: suggestedUsers
    }
  },
}

export default cruMerging;