/**
 * <input id="user_user_profile_attributes_bio" value="<%= up.object.bio %>" type="hidden" name="user[user_profile_attributes][bio]">
    <trix-editor
      input="user_user_profile_attributes_bio"
      data-controller="simple-trix"
      data-simple-trix-target="booya"
      class="trix-content focus:ring-community-600 focus:border-community-600 shadow-sm border-gray-300 rounded-md"
    ></trix-editor>
 */

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    var element = document.querySelector("trix-editor")
    this.modifyToolbar(element)
    this.noAttachments(element)
  }

  modifyToolbar(element) {
    element.addEventListener("trix-initialize", function (e) {
      let editor = e.target
      let toolbar = editor.toolbarElement
      let fileTools = toolbar.querySelector(".trix-button-group--file-tools")
      let blockTools = toolbar.querySelector(".trix-button-group--block-tools")

      fileTools.style.display = "none"
      blockTools.style.display = "none"
    })
  }

  noAttachments(element) {
    element.addEventListener("trix-file-accept", function (event) {
      event.preventDefault()
    })

    element.addEventListener("trix-attachment-add", function (event) {
      event.attachment.remove()
    })
  }
}
