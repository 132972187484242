// Example of implmenting popover
// <div data-controller="popover">
//   <div data-action="mouseenter->popover#showPopover mouseleave->popover#hidePopover" data-popover-index="0">
//     Hover over me
//     <%= render PopoverComponent.new do %>
//       Here goes your static content
//     <% end %>
//   </div>
// </div>


// import { Controller } from 'stimulus';
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['message']


  showPopover(event) {
    event.preventDefault()
    const element = event.target

    this.messageTargets.forEach((el, i) => {
      if (element.dataset.popoverIndex == i) {
        el.classList.add('absolute')
        el.classList.remove('hidden')
      }
    })
  }

  hidePopover(event) {
    event.preventDefault()
    const element = event.target

    this.messageTargets.forEach((el, i) => {
      if (element.dataset.popoverIndex == i) {
        el.classList.remove('absolute')
        el.classList.add('hidden')
      }
    })
  }

  disconnect() {
    if (this.hasMessageTarget) {
      this.messageTarget.remove();
    }
  }
}