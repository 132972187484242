// Entry point for the build script in your package.json
import Rails from "@rails/ujs"
import { Turbo } from "@hotwired/turbo-rails"

// Make accessible for Electron and Mobile adapters
window.Rails = Rails
window.Turbo = Turbo

require("@rails/activestorage").start()
import "@rails/actiontext"
require("trix")

// ActionCable Channels
import "./channels"

// Stimulus controllers
import "./controllers"

import LocalTime from "local-time"
LocalTime.start()

// ADD YOUR JAVACSRIPT HERE
import "alpine-hotwire-turbo-adapter"
import "alpinejs"
import "./src/**/*"


// Start Rails UJS
Rails.start()