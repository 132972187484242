import { patch } from "@rails/request.js"
import CheckboxSelectAll from "stimulus-checkbox-select-all"

export default class extends CheckboxSelectAll {
  static targets = ["button", "error", "errorMessage"]
  static values = {
    cohortId: Number,
  }
  connect() {
    super.connect()
  }

  async update(event) {
    event.preventDefault()
    let checked = this.checked.map((checkbox) => checkbox.value)

    const response = await patch(event.target.dataset.url, {
      body: JSON.stringify({
        enrollment_ids: checked,
        cohort_id: this.cohortIdValue,
      }),
    })
    if (response.ok) {
      Turbo.clearCache()
      Turbo.visit(window.location)
    } else {
      // render error in view
      const body = await response.json
      this.errorMessageTarget.innerText = body.message
      this.errorTarget.classList.remove("hidden")
    }
  }

  // Trigger on toggle
  toggle(e) {
    super.toggle(e)
    this.countChecked()
  }

  refresh(e) {
    super.refresh(e)
    this.countChecked()
  }

  countChecked() {
    if (this.checked.length > 0) {
      this.buttonTarget.classList.remove("hidden")
    } else {
      this.buttonTarget.classList.add("hidden")
    }
  }
}
