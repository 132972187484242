import matchingUtilities from '../matchingUtilities'

const swapMerging = {
  suggest(suggestedUsers, existingCrus, generation, startingNumber) {

    // Start with the existing Cru definitions
    let suggestedCrus = [].concat(existingCrus);

    // Fill the crus randomly
    let cruIndex = 0;

    if (cruIndex >= suggestedCrus.length) {
      suggestedCrus.push({
        generation: undefined,
        number: undefined,
        users: [],
        ig: "",
        timezone: undefined
      });
    }

    for (let userIndex = suggestedUsers.length - 1; userIndex >= 0; userIndex--) {
      if (suggestedCrus[cruIndex].users.length >= matchingUtilities.MEMBERS_PER_CRU) {
        cruIndex = cruIndex + 1;
        if (cruIndex >= suggestedCrus.length) {
          suggestedCrus.push({
            generation: undefined,
            number: undefined,
            ig: "",
            timezone: undefined
          });
        }
      }

      suggestedCrus[cruIndex].users.push(suggestedUsers[userIndex])
      suggestedUsers.splice(userIndex, 1);
    }

    // Iterate until convergence on swapping members by distance and IG date
    for (let iteration = 0; iteration < 100; iteration++) {
      // Sort each cru member on its distance to each other member in the cru
      suggestedCrus.forEach((suggestedCru) => {
        suggestedCru.users.sort((first, second) => {
          let users = suggestedCru.users;
          let dist1 = matchingUtilities.averageToUserDistance(spliceNoMutate(users, users.indexOf(first), 1), first);
          let dist2 = matchingUtilities.averageToUserDistance(spliceNoMutate(users, users.indexOf(second), 1), second);
          return dist1 - dist2;
        })
      })
    }

    // Name the Cru and set the IG
    // and if the cru is too small, return its users to unmatchable
    for (let cruIndex = suggestedCrus.length - 1; cruIndex >= 0; cruIndex--) {
      let cruToFixUp = suggestedCrus[cruIndex]
      cruToFixUp.generation = generation
      cruToFixUp.number = (startingNumber + cruIndex)

      let proposedIG = [...cruToFixUp.users[0].gatherings]

      cruToFixUp.users.forEach((user) => {
        user.gatherings.forEach((gathering) => {
          if (!proposedIG.includes(gathering)) {
            proposedIG.splice(proposedIG.indexOf(gathering), 1);
          }
        })
      })
      if (proposedIG.length === 0) {
        cruToFixUp.ig = "No Date Consensus"
      } else {
        cruToFixUp.ig = proposedIG[Math.floor(Math.random() * proposedIG.length)];
      }
    }

    return {
      crus: suggestedCrus,
      unmatchable: suggestedUsers
    }

  }
}
export default swapMerging;

function spliceNoMutate(myArray, indexToRemove) {
  return myArray.slice(0, indexToRemove).concat(myArray.slice(indexToRemove + 1));
}