import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = [
    "parentSelect",
    "childSelect",
    "grandchildSelect",
    "categoryId",
  ]
  static values = {
    parentId: Number,
    childId: Number,
    grandchildId: Number,
    url: String,
  }

  connect() {
    this.parentSelectTarget.id = Math.random().toString(36)
    this.childSelectTarget.id = Math.random().toString(36)
    this.grandchildSelectTarget.id = Math.random().toString(36)
    this.bootstrapSelects()
  }

  bootstrapSelects() {
    if (this.parentIdValue) {
      this.parentSelectTarget.value = this.parentIdValue
      if (!this.childIdValue) {
        this.getChildren({
          category_id: this.parentIdValue,
          target: this.childSelectTarget.id,
        })
      }
    }

    if (this.childIdValue) {
      this.getChildren({
        category_id: this.parentIdValue,
        target: this.childSelectTarget.id,
        selected_id: this.childIdValue,
      })

      if (!this.grandchildIdValue) {
        this.getChildren({
          category_id: this.childIdValue,
          target: this.grandchildSelectTarget.id,
        })
      }
    }

    if (this.grandchildIdValue) {
      this.getChildren({
        category_id: this.childIdValue,
        target: this.grandchildSelectTarget.id,
        selected_id: this.grandchildIdValue,
      })
    }
  }

  selectedParent(event) {
    this.categoryIdTarget.value = event.currentTarget.value
    this.grandchildSelectTarget.innerHTML = ""
    this.getChildren({
      category_id: event.currentTarget.value,
      target: this.childSelectTarget.id,
    })
  }

  selectedChild(event) {
    this.categoryIdTarget.value = event.currentTarget.value
    this.getChildren({
      category_id: event.currentTarget.value,
      target: this.grandchildSelectTarget.id,
    })
  }

  selectedGrandchild(event) {
    this.categoryIdTarget.value = event.currentTarget.value
  }

  getChildren(query) {
    get(this.urlValue, {
      query: query,
      responseKind: "turbo-stream",
    })
  }
}
