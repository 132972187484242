import { Controller } from "@hotwired/stimulus"
import { FetchRequest, get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["form", "code", "due_today", "total", "amount", "submit"]
  static values = { url: String, plan: Number }

  connect() {
    // this.duration = 1000
    // this.codeTarget.addEventListener("keydown", this.changed.bind(this))
  }

  changed(event) {
    // clearTimeout(this.timeout)
    // this.timeout == setTimeout(() => {
    //   this.checkCouponCode(this.codeTarget.value)
    // }, this.duration)
  }

  // async checkCouponCode(code) {
  //   if (this.query !== code) {
  //     this.query = code
  //     const query = new URLSearchParams({ code: code })
  //     const request = new FetchRequest("get", this.urlValue + "?" + query)
  //     const response = await request.perform()
  //     const body = await response.json
  //     if (response.ok && body.valid) {
  //       this.updateCheckOut(body)
  //       this.updatePrice(body)
  //       let hiddenInput = document.createElement('input')
  //       hiddenInput.setAttribute('type', 'hidden')
  //       hiddenInput.setAttribute('name', 'coupon')
  //       hiddenInput.setAttribute('value', body.id)
  //       this.formTarget.appendChild(hiddenInput)
  //       this.codeTarget.classList.remove("focus:border-community-600", "focus:ring-community-600", "border-gray-300")
  //       this.codeTarget.classList.add("focus:border-green-200", "focus:ring-green-200", "border-green-200")
  //       this.codeTarget.disabled = true
  //     }
  //   }
  // }

  submit(event) {
    this.formTarget.addEventListener("ajax:beforeSend", (event) => {
      event.preventDefault()
      this.submitTarget.value = "Processing"
      this.submitTarget.classList.add("animate-pulse")
      this.formTarget.submit()
    })
  }

  updatePrice(body) {
    let newPrice
    if (body.amount_off) {
      newPrice = Number((this.planValue - body.amount_off) / 100.0)
    } else if (body.percent_off) {
      newPrice = Number((this.planValue * ((100 - body.percent_off) / 100.0)) / 100.0)
    }
    if (newPrice % 1 !== 0) {
      newPrice = newPrice.toFixed(2)
    }
    this.due_todayTarget.innerHTML = `$${newPrice}`
  }

  /**
   * duration is either "once", "forever", or "repeating"
   * if it's "repeating" then duration_in_months will be populated
   */
  updateCheckOut(body) {
    let discountAmount
    let discountText = "off"

    if (body.amount_off) {
      discountAmount = `$${body.amount_off / 100}`
    } else if (body.percent_off) {
      discountAmount = `${body.percent_off}%`
    }

    if (body.duration === "repeating") {
      discountText = `for ${body.duration_in_months} months`
    }

    this.codeTarget.value = `${discountAmount} ${discountText}`
    this.codeTarget.classList.add("text-center")
  }
}
