import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "active", "item"]
  connect() {
    if (this.inputTarget.value != undefined) {
      this.itemTargets.forEach((e) => {
        if (e.dataset.selectItemIdValue == this.inputTarget.value) {
          e.classList.add("bg-milk-punch-100", "border-community-600")
          let logo = e.querySelector("#logo")
          if (logo != undefined) {
            logo.classList.add("text-community-600")
          }
          let category = e.querySelector("#category")
          category.classList.remove("text-gray-500")
          category.classList.add("text-gray-900")
        }
      })
    }
  }

  set(event) {
    this.removeActive()
    let itemId = event.currentTarget.dataset.selectItemIdValue
    this.inputTarget.value = itemId
    event.currentTarget.classList.add("bg-milk-punch-100", "border-community-600")
    let logo = event.currentTarget.querySelector("#logo")
    if (logo != undefined) {
      logo.classList.add("text-community-600")
    }
    let category = event.currentTarget.querySelector("#category")
    category.classList.remove("text-gray-500")
    category.classList.add("text-gray-900")
  }

  removeActive() {
    this.itemTargets.forEach((e) => {
      e.classList.remove("bg-milk-punch-100", "border-community-600")
      let logo = e.querySelector("#logo")
      if (logo != undefined) {
        logo.classList.remove("text-community-600")
      }
      let category = e.querySelector("#category")
      category.classList.remove("text-gray-900")
      category.classList.add("text-gray-500")
    })
  }
}
