import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'
import tippy from "tippy.js"

export default class extends Controller {
  static targets = ['checkbox', 'text']

  static values = {
    completed: Boolean,
  }

  connect() {
    this.toggleClasses()
    tippy(document.querySelectorAll("[data-tippy-content]"), {
      theme: "dark",
    })

    // This is to break out of the turbo_frame and open links
    // that are in the actions rich text
    this.textTargets.forEach(e => {
      e.querySelectorAll('a').forEach(link => {
        let regex = /(?:[\w-]+\.)+[\w-]+/
        let hrefAttribute = link.getAttribute('href')
        let domain = regex.exec(hrefAttribute)

        if (domain != null && domain[0] == "app.thecru.com") {
          link.setAttribute("target", "_top")
        } else {
          link.setAttribute("target", "_blank")
        }
      })
    })
  }

  toggle() {
    this.toggleDatabase()
  }

  async toggleDatabase() {
    const request = new FetchRequest("patch", this.data.get("url"), { responseKind: 'turbo-stream' })
    const response = await request.perform()
    if (response.ok) {
      this.completedValue = !this.completedValue
      this.toggleClasses()
    }
  }

  toggleClasses() {
    if (this.completedValue === true) {
      this.textTarget.classList.add('line-through')
    } else {
      this.textTarget.classList.remove('line-through')
    }
  }
}
