import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"
import { enter, leave } from "el-transition"

export default class extends Controller {
  static targets = ["container", "content"]
  static values = {
    cohortEnrollment: Object,
    cohortEnrollmentUrl: String,
  }

  connect() {
    let seen = this.cohortEnrollmentValue["modal_seen"]
    if (seen == false) {
      this.open()
    }
  }

  open() {
    this.containerTarget.classList.remove("hidden")
    enter(this.contentTarget)
    setTimeout(() => {
      this.updateCohortEnrollment()
    }, 1000)
  }

  async close(e) {
    if (e) e.preventDefault()
    await leave(this.contentTarget)
    this.containerTarget.classList.add("hidden")
  }

  async updateCohortEnrollment() {
    const response = await patch(this.cohortEnrollmentUrlValue, {
      body: JSON.stringify({
        cohort_enrollment: this.cohortEnrollmentValue,
      }),
    })
    if (!response.ok) {
      const body = await response.json
      console.log("error body : ", body)
    }
  }
}
