document.addEventListener("turbo:load", function () {
  let dashboard = document.querySelector("#dashboard")
  if (dashboard !== null) {
    freshpaint.track("Dashboard Page View")
  }
})

document.addEventListener("turbo:load", function () {
  let signIn = document.querySelector("#signIn")
  if (signIn !== null) {
    freshpaint.track("Sign In Page View")
  }
})
