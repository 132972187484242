import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import { FetchRequest } from "@rails/request.js"

export default class extends Controller {

  connect() {
    // Instead of onEnd this can be moved over to onChange but will have to research more
    // onChange: ((event) => { console.log("change event : ", event) })
    this.sortable = Sortable.create(this.element, {
      group: "shared",
      animation: 150,
      handle: ".drag-handle",
      onEnd: this.end.bind(this),
    })
  }

  async end(event) {
    event.preventDefault()
    let url = event.item.dataset.moveUrl

    let intentions = [...event.to.children]
    let intention_ids = intentions.map(item => parseInt(item.dataset.intentionId))

    console.log(intention_ids)

    const request = new FetchRequest("post", url, {
      body: JSON.stringify({
        intentions: intention_ids
      })
    })
    await request.perform()
  }
}