import { Controller } from "@hotwired/stimulus"
import tippy from "tippy.js"

export default class extends Controller {

  connect() {
    this.tippyElement = document.querySelectorAll('[data-tippy-content]')
    this.tippyInstance = tippy(this.tippyElement), {
      theme: "dark"
    }
  }

  disconnect() {
    this.tippyElement = ''
    this.tippyInstance.forEach((element) => {
      element.destroy();
    })

  }
}
