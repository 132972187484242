import matchingUtilities from "../matchingUtilities";

const bucketMerging = {

  suggest(suggestedUsers, existingCrus, generation, startingNumber) {
    // TODO: Fill existingCrus first
    //       Right now this only operates on suggestUsers

    let buckets = [];

    // Start by collecting all single IG date users into buckets
    suggestedUsers.forEach((user) => {
      user.gatherings.forEach((gathering) => {
        if (!buckets.find((bucket) => bucket.date === gathering)) {
          buckets.push({ date: gathering, users: [] });
        }
      });
      if (user.gatherings.length === 1) {
        buckets
          .find((bucket) => bucket.date === user.gatherings[0])
          .users.push(user);
        suggestedUsers.splice(suggestedUsers.indexOf(user), 1);
      }
    });

    // Sort users by IG count so that we manage the needs of the least flexible users
    suggestedUsers.sort((first, second) => {
      return first.gatherings.length - second.gatherings.length;
    });

    // Fill the rest of users into ig buckets
    suggestedUsers.forEach((user) => {
      let closeness = [];

      for (let bucketIndex = 0; bucketIndex < buckets.length; bucketIndex++) {
        closeness[bucketIndex] = matchingUtilities.averageToUserDistance(
          buckets[bucketIndex].users,
          user
        );
      }

      // Start by filling with the the ones that are closest
      let selected = 0;
      for (let i = 0; i < buckets.length; i++) {
        if (closeness[i] < closeness[selected]) {
          selected = i;
        }
      }

      // Insert into the bucket with the closest
      buckets[selected].users.push(user);
    });
    suggestedUsers.splice(0);

    // Balance buckets and cluster
    for (let attempt = 0; attempt < 16; attempt++) {
      // Sort the bucket by distance from a full cru
      buckets.sort((first, second) => {
        const firstDistance =
          matchingUtilities.MEMBERS_PER_CRU - (first.users.length % MEMBERS_PER_CRU);
        const secondDistance =
          matchingUtilities.MEMBERS_PER_CRU - (second.users.length % MEMBERS_PER_CRU);

        return firstDistance - secondDistance;
      });

      // Sort each bucket's users by closeness to the shortest buckets users
      for (let i = 1; i < buckets.length; i++) {
        buckets[i].users.sort((first, second) => {
          const firstCloseness = matchingUtilities.averageToUserDistance(
            buckets[0].users,
            first
          );
          const secondCloseness = matchingUtilities.averageToUserDistance(
            buckets[0].users,
            second
          );

          return firstCloseness - secondCloseness;
        });
      }

      // Until you find an insertible user, look from largest
      // to smallest at the 0th user sorted by distance
      for (let i = buckets.length - 1; i > 0; i--) {
        let candidate = buckets[i].users[0];
        if (
          buckets[i].users.length % MEMBERS_PER_CRU != 0 &&
          matchingUtilities.userDistance(buckets[0].users[0], candidate) <
          Number.POSITIVE_INFINITY
        ) {
          buckets[0].users.push(candidate);
          buckets[i].users.splice(buckets[i].users.indexOf(candidate), 1);
          break;
        }
      }
    }

    // Fill in Crus
    let cruByBucket = [];
    let startingCruNumber = existingCrus.length + startingNumber;

    buckets.forEach((bucket) => {
      let numCru = parseInt(bucket.users.length / matchingUtilities.MEMBERS_PER_CRU);

      if (bucket.users.length % matchingUtilities.MEMBERS_PER_CRU >= matchingUtilities.MEMBERS_PER_CRU / 2) {
        numCru = numCru + 1;
      }

      if (numCru === 0) {
        numCru = 1;
      }

      let crus = [];

      for (let i = 0; i < numCru; i++) {
        crus.push({
          generation: generation,
          number: startingNumber + i,
          users: [],
          ig: bucket.date,
          timezone: undefined
        });
        startingCruNumber = startingCruNumber + 1;
      }

      // Sort on an arbitrary user and use that to seed cru
      let lucky = bucket.users[0];
      bucket.users.sort((first, second) => {
        const firstDist = matchingUtilities.userDistance(lucky, first);
        const secondDist = matchingUtilities.userDistance(lucky, second);
        return firstDist - secondDist;
      });

      let skip = parseInt(bucket.users.length / crus.length);
      for (let i = 0; i < crus.length; i++) {
        let userIndex = i * skip;
        crus[i].users.push(bucket.users[userIndex]);
        bucket.users.splice(bucket.users[userIndex], 1);
      }

      // Round robin pick
      let cruIndex = 0;
      while (bucket.users.length > 0) {
        // Sort on that cru
        bucket.users.sort((first, second) => {
          const distFirst = matchingUtilities.averageToUserDistance(
            crus[cruIndex].users,
            first
          );
          const distSecond = matchingUtilities.averageToUserDistance(
            crus[cruIndex].users,
            second
          );
          return distFirst - distSecond;
        });

        crus[cruIndex].users.push(bucket.users[0]);
        bucket.users.splice(bucket.users[0], 1);
        cruIndex = (cruIndex + 1) % crus.length;
      }
      cruByBucket.push(crus);
    });

    // Swap to improve stats and balance
    // TODO: This



    // Merge into the final cru list
    let finalCrus = [];

    cruByBucket.forEach((crus) => {
      finalCrus = finalCrus.concat(crus);
    });

    return {
      crus: finalCrus,
      unmatchable: suggestedUsers
    }
  },
}

export default bucketMerging;