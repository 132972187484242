import { Controller } from "@hotwired/stimulus";
import Cropper from 'cropperjs';
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  static targets = [
    "input",
    "imageField",
    "imageCanvas",
    "imageCropButton",
    "attachedImage",
    "cropImageWrapper",
    "result"
  ]

  connect() {
  }

  disconnect() {
    this.cropper.destroy()
  }

  dontCrop(event) {
    event.preventDefault()
    this.cropImageWrapperTarget.classList.add("hidden")
    this.attachedImageTarget.classList.remove("hidden")
    this.imageFieldTarget.classList.remove("hidden")
    this.resultTarget.classList.add("hidden")
    this.cropper.destroy()
  }

  selectedImage(event) {
    event.preventDefault()

    this.url = this.inputTarget.dataset.directUploadUrl
    this.name = this.inputTarget.name
    const MAX_SIZE = 10485760

    if (this.inputTarget && this.inputTarget.files[0]) {
      let size = this.inputTarget.files[0].size

      if (size > MAX_SIZE) {
        alert("Maximum file size exceeds 10 mb")
        return
      } else if (size < MAX_SIZE) {
        this.setSrc()
      }
    }
  }

  setSrc() {
    let input = this.inputTarget
    let output = this.imageCanvasTarget

    if (input.files && input.files[0]) {
      let that = this;
      let reader = new FileReader();

      reader.onload = () => {
        output.src = reader.result

        that.attachedImageTarget.classList.add("hidden")
        // if (that.resultTarget.classList.contains("hidden")) {
        // that.resultTarget.classList.remove("hidden")
        // } else {
        that.resultTarget.classList.toggle("hidden")
        // }

        if (that.imageCropButtonTarget.classList.contains("hidden")) {
          that.imageCropButtonTarget.classList.toggle("hidden")
        }
        that.cropImageWrapperTarget.classList.toggle("hidden")
        that.imageFieldTarget.classList.toggle("hidden")

        that.setPreview(output)
      }
      reader.readAsDataURL(input.files[0]);
    }
  }

  setPreview(output) {
    this.cropper = new Cropper(output, {
      aspectRatio: 1 / 1,
      minCropBoxHeight: 100,
      minCropBoxWidth: 100,
      viewMode: 2,
      zoomable: true
    })
    let that = this;

    this.imageCropButtonTarget.addEventListener('click', (event) => {
      event.preventDefault()
      // Hide apply button
      that.imageCropButtonTarget.classList.add("hidden")
      // show input field / button again
      that.imageFieldTarget.classList.remove("hidden")

      let croppedCanvas = this.cropper.getCroppedCanvas();
      let roundedCanvas = getRoundedCanvas(croppedCanvas);
      let roundedImage = document.createElement('img');

      that.cropImageWrapperTarget.classList.add("hidden")

      croppedCanvas.toBlob((blob) => {
        blob.name = this.name
        this.createNewImageFile(blob)
      })

      roundedImage.src = roundedCanvas.toDataURL()
      this.resultTarget.innerHTML = '';
      this.resultTarget.classList.remove("hidden")
      this.resultTarget.appendChild(roundedImage);
    })
  }

  createNewImageFile(blob) {
    const newImage = new File([blob], blob.name, { type: blob.type });
    this.uploadFile(newImage)
  }

  uploadFile(file) {
    const upload = new DirectUpload(file, this.url)

    upload.create((error, blob) => {
      if (error) {
        // ....
      } else {

        const hiddenField = document.createElement('input')
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("value", blob.signed_id);
        hiddenField.name = this.name
        this.imageFieldTarget.appendChild(hiddenField)

        this.cropper.destroy()
      }
    })
  }
}

function getRoundedCanvas(sourceCanvas) {
  var canvas = document.createElement('canvas');
  var context = canvas.getContext('2d');
  var width = sourceCanvas.width;
  var height = sourceCanvas.height;

  canvas.width = width;
  canvas.height = height;
  context.imageSmoothingEnabled = true;
  context.drawImage(sourceCanvas, 0, 0, width, height);
  context.globalCompositeOperation = 'destination-in';
  context.beginPath();
  context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
  context.fill();
  return canvas;
}