import { Controller } from "@hotwired/stimulus"
import confetti from 'canvas-confetti'

export default class extends Controller {

  connect() {
  }

  afterComplete(element) {
    var myCanvas = document.createElement('canvas');
    myCanvas.classList.add("absolute", "inset-0", "z-50", "w-screen")
    document.body.appendChild(myCanvas);

    var myConfetti = confetti.create(myCanvas, {
      resize: true,
      useWorker: true,
    });

    myConfetti({
      particleCount: 100,
      spread: 150,
      origin: { y: 0.6 },
    });

    setTimeout(() => {
      myCanvas.classList.remove("absolute", "inset-0", "z-50", "w-screen")
      document.body.removeChild(myCanvas)
      myConfetti.reset();
    }, 2000)
  }
}
