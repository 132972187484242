// Tippy exmaple in html
// <div
//   data-tippy-content="Whatever text you want to show"
//   data-tippy-placement="top"
//   data-tippy-delay="0"
//   data-tippy-arrow="true"
//   data-tippy-size="regular"
//   data-tippy-animation="scale">
// </div>

import tippy from "tippy.js"

document.addEventListener("turbo:load", () => {
  tippy(document.querySelectorAll("[data-tippy-content]"), {
    theme: "dark",
  })
})
