import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["categories", "open", "close"]
  connect() {
  }

  toggle(event) {
    event.preventDefault()

    if (this.hasCategoriesTarget && this.categoriesTarget.classList.contains("hidden")) {
      this.categoriesTarget.classList.remove("hidden")
      this.openTarget.classList.remove("hidden")
      this.closeTarget.classList.add("hidden")
    } else {
      this.categoriesTarget.classList.add("hidden")
      this.openTarget.classList.add("hidden")
      this.closeTarget.classList.remove("hidden")
    }
  }
}