import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["errors"]

  connect() {
    this.element.addEventListener("keydown", this.submitForm)
  }

  disconnect() {
    this.element.removeEventListener("keydown", this.submitForm)
  }

  submitForm(e) {
    if (e && e.key === "Enter") {
      if (e.shiftKey) {
        return;
      } else {
        e.preventDefault();
        this.requestSubmit()
      }
    }
  }
}
