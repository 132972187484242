import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = ["dropdown"]
  static values = {
    user: Object,
  }

  toggle(event) {
    event.preventDefault()
    this.dropdownTarget.classList.toggle("hidden")
  }

  async update(event) {
    event.preventDefault()

    let userId = this.userValue["id"]
    let url = `/backoffice/applicants/state`
    let payload = {
      applicant: {
        user_id: userId,
        state: event.target.dataset.state,
      }
    }

    const request = new FetchRequest("patch", url, {
      body: JSON.stringify(payload),
      responseKind: "turbo-stream",
    })
    const response = await request.perform()

    this.dropdownTarget.classList.toggle("hidden")

    if (response.ok) {
      setTimeout(() => {
        Turbo.clearCache()
        Turbo.visit(window.location)
      }, 2000)

    } else {
      // Raise error
      console.log("Error")
    }
  }
}
