import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use"

export default class extends Controller {
  static targets = ["modal", "overlay"];
  connect() {
    useClickOutside(this, { element: this.modalTarget });
  }

  close(event) {
    event.preventDefault();
    this.overlayTarget.classList.add("hidden");
  }

  clickOutside(event) {

    if (this.hasModalTarget) {
      if (this.overlayTarget.classList.contains("hidden") != true) {
        this.overlayTarget.classList.add("hidden");
      }
    } else {
      return;
    }
  }
}