import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ["hostSelect"]

  static values = {
    cruId: Number,
  }

  selectedCru(event) {
    let cruId = event.currentTarget.value
    let target = this.hostSelectTarget.id

    get(`/backoffice/gatherings/cru_users?target=${target}&cru_id=${cruId}`, {
      responseKind: "turbo-stream"
    })
  }
}