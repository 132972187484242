import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "card", "error", "button"]

  static values = {
    stripePublicKey: String,
    setupIntent: String,
    name: String,
  }

  connect() {
    this.stripe = Stripe(this.stripePublicKeyValue)
    let elements = this.stripe.elements()
    this.card = elements.create("card")
    this.card.mount(this.cardTarget)
    this.card.addEventListener("change", this.changed.bind(this))
  }

  submit(event) {
    this.formTarget.addEventListener('ajax:beforeSend', async (event) => {
      event.preventDefault();
      this.buttonTarget.classList.add("animate-pulse")
      this.buttonTarget.value = "Submitting..."
      const { setupIntent, error } = await this.stripe.confirmCardSetup(
        this.setupIntentValue,
        {
          payment_method: {
            card: this.card,
            billing_details: {
              name: this.nameValue,
            }
          }
        }
      );
      if (error) {
        const errorElement = this.errorTarget;
        this.errorTarget.textContent = error.message;
        this.buttonTarget.classList.remove("animate-pulse")
        this.buttonTarget.value = "Update Card"
      } else {
        if (setupIntent.status === 'succeeded') {
          let hiddenInput = document.createElement('input');
          hiddenInput.setAttribute('type', 'hidden');
          hiddenInput.setAttribute('name', 'account[payment_method]');
          hiddenInput.setAttribute('value', setupIntent.payment_method);
          this.formTarget.appendChild(hiddenInput)
          this.formTarget.submit()
        }
      }
    })
  }

  changed(event) {
    if (event.error) {
      this.errorTarget.textContent = event.error.message
    } else {
      this.errorTarget.textContent = ""
    }
  }

  keydown(event) {
    if (event.keyCode == 13) {
      event.preventDefault()
      this.submit(event)
    }
  }
}
